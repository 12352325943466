import React, { useEffect, useState } from 'react';

import PopUp from 'components/modal';

import stopPropagation from 'utils/eventFunc';

import { useLazyQuery } from '@apollo/client';

import { GET_TICKET } from 'lib/api';

import Loader from 'components/loader';
import Probability from 'components/bets/probability';
import { useToastOnEffect } from 'utils/customHooks';

import Visibility from 'public/icons/visibility.svg';

import styles from './modal.module.css';

const ViewModal = ({ ticketId, event = [] }) => {
  const [getEvents, { loading, error, data = {} }] = useLazyQuery(GET_TICKET, { variables: { id: ticketId } });
  const [open, setOpen] = useState(false);
  const handleOpen = (ev) => {
    ev.preventDefault();
    stopPropagation(ev);
    setOpen(true);
    if (ticketId) {
      getEvents();
    }
  };
  const handleClose = (ev) => {
    stopPropagation(ev);
    setOpen(false);
  };
  const pool = (data.findTickets || [])[0] || {};
  const events = (pool.events || event || []);
  const sortedEvents = [...events].sort((v1, v2) => v1.startTime - v2.startTime);

  useEffect(() => {
    if (error) {
      setOpen(false);
    }
  }, [error]);

  useToastOnEffect('Something went wrong 😔. Probability not available.', [error], 'error');

  return (
    <>
      <Visibility className="w-8 h-8 inline-block" onClick={handleOpen} />
      <PopUp
        open={open}
        handleClose={handleClose}
        title="Preview events & points"
        titleClass="text-center mb-8"
      >
        {loading && <Loader />}
        {sortedEvents.map((it, i) => (
          <Probability
            className={styles.modal}
            key={it.id}
            state={it.state}
            index={i + 1}
            probability={it.probability}
            time={it.startTime}
            team1={it.team1}
            team2={it.team2}
            addScore={() => {}}
            deleteActive={() => {}}
            activeScores={[]}
          />
        ))}
      </PopUp>
    </>
  );
};

export default ViewModal;
