import cx from 'classnames';
import Link from 'next/link';
import WhiteBox from 'components/elems/whitebox';
import ViewModal from 'components/bets/modal';

import { FormatsMeta, getRulesName, competitionNames } from 'utils/game';
import { formatMsToOpt } from 'utils/date';

import styles from './poolBlock.module.css';

const PoolBlock = (
  {
    id,
    name,
    prices,
    eventIds,
    events = [],
    rounds = [],
    startAt,
    small = false,
    callBack = () => {},
    className,
  },
) => {
  const encodeId = Buffer.from(id || '').toString('base64');
  const isAllEventsIsSameCom = events.every(({ competition }) => events[0]?.competition?.name === competition?.name);
  const firstName = events[0]?.competition?.name?.toLowerCase() || '';
  const classFromName = isAllEventsIsSameCom ? 
    Object.entries(competitionNames).find(([key]) => firstName.includes(key))?.[1] : competitionNames.euroweek;

  return (
    <div className={cx('relative', className)}>
      <WhiteBox
        aria-label={`pool - ${name}`}
        elem="button"
        type="button"
        onClick={callBack}
        className={cx(styles.poolItem, styles[classFromName])}
        key={id}
      >
        {startAt && (
          <span className={cx(styles.time, 'text-xs block pt-4')}>
            {`Starting on ${formatMsToOpt(startAt * 1000)}`}
          </span>
        )}
        <h2 className={`text-lg text-center mb-${small ? '2' : '4'}`}>
          <span className={cx(styles.poolTitle, {
            'pt-10 mt-4 text-2xl': !small,
            [styles.f]: !small,
            'mt-2': small,
          })}
          >
            { name }
            {' '}
            {small && (
              <span className="block text-base">
                {eventIds?.length || 0}
                {' '}
                events
              </span>
            )}
          </span>
        </h2>
        <div className={cx(styles.poolFormats, 'flex-center')}>
          {!small && FormatsMeta.map((it) => (
            <div
              key={it.id}
              className={cx(styles.poolFormat,
                'text-lg text-center py-3 px-2 bg-white rounded-lg shadow', {
                  [styles.hidden]: prices && (prices & it.id) === 0,
                })}
            >
              {it.multiplier}
            </div>
          ))}
        </div>
        {!small && (
          <p className={cx('text-lg', { 'mt-4': !small })}>
            <span className="font-bold text-lg">
              {`${eventIds?.length || 0} events`}
            </span>
            {' - '}
            <span className="font-bold text-lg">
              {`Rules ${getRulesName(rounds)}`}
            </span>
          </p>
        )}
        <span className={styles.buton}>
          JOIN the SYSTEM POOLS
        </span>
        <p className={styles.systemInfo}>
          and face off against random opponent
        </p>
        <p className={cx(`flex-center pt-${small ? '0' : '4'} pb-${small ? '4' : '6'}`, styles.lastItem)}>
          Preview
          <span className={styles.poolView}>
            <ViewModal ticketId={id} />
          </span>
        </p>
      </WhiteBox>
      <div className={cx(styles.toPrivate, 'relative')}>
        <div className={cx(styles.orBlock)}>
          OR
        </div>
        <Link
          href="/private"
          as={`/private/?t=${encodeId}`}
        >
          <button
            className={cx('whitespace-no-wrap py-1 px-4 bg-primary-light text-black rounded-3xl global-button', styles.privateButton)}
          >
            Create PRIVATE POOL
          </button>
        </Link>
        <span className={styles.prInfo}>
          and share the tournament link with your friends
        </span>
      </div>
    </div>
  );
};

export default PoolBlock;
