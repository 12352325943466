import { useEffect, useRef, useState } from "react";
import cx from "classnames";

import styles from './small.module.css';

const SmallCarousel = ({ children, stepInd = 2, className, scrollToIndex = null }) => {
  const containerRef = useRef();
  const [start, setStart] = useState(true);
  const [end, setEnd] = useState(false);

  useEffect(() => {
    const scrollHandler = (ev) => {
      ev?.stopPropagation();
      const container = containerRef.current;
      setEnd(container?.scrollLeft + container.offsetWidth >= container.scrollWidth);
      setStart(container?.scrollLeft <= 24);
    };

    containerRef.current?.addEventListener?.("scroll", scrollHandler, { passive: true });

    if (containerRef.current) {
      setTimeout(() => scrollHandler(), 200);
    }

    return () => {
      containerRef.current?.removeEventListener?.("scroll", scrollHandler, { passive: true });
    }
  }, [containerRef.current]);

  useEffect(() => {
    if (containerRef.current && scrollToIndex) {
      const container = containerRef.current;
      const step = (container.scrollWidth / children.length) * scrollToIndex;
      const nextScroll = container?.scrollLeft + step;
      container.scrollLeft = nextScroll;
    }
  }, [containerRef.current, scrollToIndex]);

  const go = (nextB) => {
    const mul = nextB ? stepInd : stepInd * -1;
    const container = containerRef.current;
    const step = (container?.scrollWidth / children.length) * mul;
    const nextScroll = container?.scrollLeft + step;
    container.scrollLeft = nextScroll;

    setEnd(nextScroll + container.offsetWidth >= container.scrollWidth);
    setStart(nextScroll <= 24);
  }

  if (!children || children?.length === 0) {
    return null;
  }

  return (
    <div className={cx(styles.container, className)}>
      <div onClick={() => go(false)} className={cx(styles.arrow, styles.prev, { [styles.disabled]: start })}>
        <svg width={18} height={18} focusable="false" viewBox="0 0 24 24">
          <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z" />
        </svg>
      </div>
      <div className={styles.box} ref={containerRef}>
        { children }
      </div>
      <div onClick={() => go(true)} className={cx(styles.arrow, styles.next, { [styles.disabled]: end })}>
        <svg width={18} height={18} focusable="false" viewBox="0 0 24 24">
          <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z" />
        </svg>
      </div>
    </div>
  )
};

export default SmallCarousel;
