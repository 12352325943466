import {
  useState, useCallback, useEffect,
} from 'react';

import { extractScoresAsId } from 'utils/game';

import Container from './container';

const Index = ({
  probability,
  index,
  time,
  team1,
  team2,
  state,
  addScore,
  deleteActive,
  activeScores = [],
  maxLines = 0,
  activeScroll = false,
  className,
}) => {
  const [currentScore, setCurrentScore] = useState(0);
  const [currentActive, setCurrentActive] = useState(0);
  const activeHandler = (score) => {
    const getScore = (active) => {
      const extracted = extractScoresAsId(active);
      let newActive = active;

      if ((newActive & score) > 0) {
        return newActive ^ score;
      }
      if (extracted.length === 2) {
        newActive ^= extracted[1];
      }
      return newActive | score;
    };
    const newScore = getScore(currentScore);
    setCurrentScore(newScore);
    addScore(newScore, currentActive);
  };
  const addNewLine = useCallback((ind) => {
    addScore(0);
    setCurrentScore(0);
    setCurrentActive(ind);
  }, []);
  const deleteHandler = useCallback((it) => {
    deleteActive(it);
    setCurrentScore(0);
  }, []);
  const setActive = useCallback((i) => {
    setCurrentActive(i);
  }, []);

  useEffect(() => {
    let active = currentActive;
    const lastInd = activeScores.length - 1;
    if (active > lastInd) {
      active = lastInd;
      setCurrentActive(lastInd);
    }
    setCurrentScore(activeScores[active]);
  }, [currentActive, activeScores]);

  useEffect(() => {
    if (activeScores.length === 1 && !activeScores[0]) {
      setCurrentActive(0);
    }
  }, [activeScores]);

  useEffect(() => {
    setCurrentActive(0);
  }, [maxLines]);

  return (
    <Container
      className={className}
      index={index}
      time={time}
      team1={team1}
      team2={team2}
      state={state}
      maxLines={maxLines}
      currentScore={currentScore}
      currentActive={currentActive}
      activeScores={activeScores}
      probability={probability}
      addNewLine={addNewLine}
      activeHandler={activeHandler}
      deleteHandler={deleteHandler}
      setActive={setActive}
      activeScroll={activeScroll}
    />
  );
};

export default Index;
