import {
  useCallback, useState, useEffect, useContext,
} from 'react';
import UserContext from 'utils/context';

import PopUp from 'components/modal';
import SignIn from 'components/signIn';

import styles from './styles.module.css';

const ByTriggerSignIn = ({
  children,
  show = false,
  closeCallBack,
  actionCallBack = () => {},
}) => {
  const { user, loading } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
    closeCallBack();
  }, []);
  const handleSignIn = useCallback(() => {
    setOpen(false);
    actionCallBack();
  });

  useEffect(() => {
    const root = document.getElementById('__next');

    root.classList.toggle(styles.blur, open);
  }, [open]);

  useEffect(() => {
    if (!loading && !user && user !== undefined && show) {
      setOpen(true);
    }
    if (user && show) {
      actionCallBack();
    }
  }, [user, loading, show]);

  return (
    <>
      { children }
      <PopUp title="LOG IN" open={open} handleClose={handleClose}>
        <SignIn className={styles.popUp} callBack={handleSignIn} />
      </PopUp>
    </>
  );
};

export default ByTriggerSignIn;
